<template>
  <div class="pt-36 bg-gray-100 pb-20">
    <div
      class="doc relative mx-auto rounded-lg"
      style="max-width: 800px"
      id="politicas-cookies"
    >
      <section class="z-50 container mx-auto px-4 pdf-item py-12">
        <div class="mx-auto prose text-justify">
          <h1
            class="text-left uppercase mb-4 text-gray-900 font-bold"
            style="font-size: 22px"
          >
            Política Cookies
          </h1>

          <p class="pdf-item">
            Esta Política de Cookies explica como <b>{{ nm_fantasia }}</b>
            usa cookies e tecnologias semelhantes para reconhecê-lo quando você
            visita nosso site ou plataforma digital em <a class="link"
                                                          :href="cd_site_url">{{ cd_site_url }}</a>.
            Esse documento explica o que são essas tecnologias e por que as usamos, bem como seus
            direitos de administrar nosso uso delas.
          </p>

          <p class="pdf-item">Em alguns casos, podemos usar cookies para coletar dados pessoais ou
            informações que, se combinadas com outras, tornam-se dados pessoais. </p>

          <h3>O que são cookies?</h3>

          <p class="pdf-item">
            Os cookies são arquivos digitais com pequenos fragmentos de dados (e geralmente com um
            identificador único) que são salvos e armazenados no dispositivo do usuário de uma
            plataforma digital. Eles podem ser classificados como cookies temporários (sendo
            automaticamente apagados quando o navegador ou aplicativo é encerrado) ou cookies
            persistentes (que permanecem armazenados no dispositivo até uma data de expiração
            definida), bem como cookies originais (definidos pelo responsável que opera a
            plataforma) ou cookies terceiros (definidos por aplicações sob responsabilidade de
            terceiros).
          </p>

          <p class="pdf-item">
            Os cookies podem ser categorizados conforme o seu propósito. Existem cookies que são
            estritamente necessários para o correto funcionamento das plataformas, enquanto outros
            coletam dados estatísticos com a finalidade de analisar a utilização da plataforma e
            seu respectivo desempenho. Também há cookies que são utilizados para assegurar a
            disponibilização de funcionalidades adicionais das plataformas ou para guardar as
            preferências definidas pelo usuário no uso da plataforma, sempre que utilizar o mesmo
            dispositivo. Já outros cookies podem servir, ainda, para medir o sucesso de
            aplicações e a eficácia da publicidade de terceiros.
          </p>

          <h3>
            Por que utilizamos Cookies?
          </h3>

          <p class="pdf-item">
            Atualmente, a utilização de cookies é comum em qualquer plataforma digital. Seu uso
            não prejudica de forma alguma os dispositivos (computadores, tablets, celulares, etc.)
            dos usuários em que são armazenados. Eles aprimoram a experiência do usuário, tanto
            em termos de performance, como em termos de usabilidade, uma vez que os conteúdos
            disponibilizados serão direcionados às necessidades e expectativas do usuário.
          </p>

          <p class="pdf-item">
            Os cookies permitem que uma plataforma digital memorize informações sobre a visita do
            usuário, o seu idioma preferido, a sua localização, a recorrência das suas sessões
            e outras variáveis que nós consideramos relevantes para tornar a sua experiência
            muito mais eficiente.
          </p>

          <p class="pdf-item">
            Os cookies também poderão ser utilizados para compilar estatísticas anônimas e
            agregadas que permite entender como os usuários utilizam as nossa plataforma, bem como
            para aprimorar suas estruturas e conteúdo. É importante ressaltar que não podemos
            identificá-lo pessoalmente por meio desses dados.
          </p>

          <template v-if="is_utilizado_cookie">
            <h3>Quais tipos de cookies utilizamos?</h3>

            <template v-if="is_utilizado_cookie_necessarios">
              <p class="pdf-item"><b>Cookies essenciais do site:</b></p>
              <p class="pdf-item">
                Esses cookies são essenciais para que as nossas plataformas digitais funcionem
                corretamente. Eles permitem que o usuário navegue em nossos sites e use todas suas
                funcionalidades. Os exemplos incluem lembrar ações anteriores (por exemplo, textos
                inseridos) quando você volta a navegar em uma página em uma mesma sessão.
              </p>
            </template>

            <template v-if="is_utilizado_cookie_desempenho">
              <p class="pdf-item"><b>Cookies de Pesquisa, Análise e Desempenho:</b></p>
              <p class="pdf-item">
                A finalidade deste tipo de cookie é ajudar a entender o desempenho das nossas
                plataformas digitais, medir sua audiência, verificar os hábitos de navegação dos
                usuários, bem como a forma pelo qual chegou à página deste (por exemplo, através
                de links de outros sites, buscadores ou diretamente pelo endereço).
              </p>
            </template>

            <template v-if="is_utilizado_cookie_marketing">
              <p class="pdf-item"><b>Cookies de publicidade:</b></p>
              <p class="pdf-item">
                Esses cookies são usados para tornar as mensagens publicitárias mais relevantes
                para você. Eles executam funções como impedir que o mesmo anúncio reapareça
                continuamente, garantindo que os anúncios sejam exibidos de maneira adequada para os
                anunciantes e, em alguns casos, selecionando anúncios que são baseados em seus
                interesses.
              </p>
            </template>

            <template v-if="is_utilizado_cookie_rede_sociais">
              <p class="pdf-item"><b>Cookies de redes sociais:</b></p>
              <p class="pdf-item">
                Esses cookies são usados para permitir que você compartilhe páginas e conteúdo que
                considere interessantes em nossos sites por meio de redes sociais de terceiros e
                outros sites.Esses cookies também podem ser usados para fins publicitários.
              </p>
            </template>

            <template v-if="is_utilizado_cookie_auth">
              <p class="pdf-item"><b>Cookies de autenticação:</b></p>
              <p class="pdf-item">
                Servem para reconhecer um determinado usuário, possibilitando o acesso e
                utilização da Plataforma com conteúdo e/ou serviços restritos e proporcionando
                experiências de navegação mais personalizadas.
              </p>
            </template>
          </template>

          <h3>
            Como posso controlar ou excluir cookies?
          </h3>

          <p class="pdf-item">
            A maioria dos navegadores de Internet são configurados para aceitar automaticamente os
            cookies. O usuário pode alterar as configurações para bloquear o uso de cookies ou
            alertá-lo quando um cookie estiver sendo enviado para seu dispositivo.
          </p>

          <p class="pdf-item">
            Existem várias formas de gerenciar cookies. Para saber mais sobre como ajustar ou
            alterar as configurações do seu navegador, consulte as instruções do mesmo ou a
            seção de ajuda.
          </p>

          <p class="pdf-item">
            Alertamos que desabilitar os cookies que utilizamos pode impactar a sua experiência nas
            nossas plataformas digitais. Como exemplo: você não conseguirá autenticar no Canal do
            Cliente.
          </p>

          <p class="pdf-item">
            Caso o usuário utilize dispositivos diferentes (como computador, smartphone, tablet,
            dentre outros) para acessar as nossas plataformas digitais, deverá se assegurar que
            cada navegador, de cada dispositivo está ajustado para atender suas preferências
            quanto aos cookies.
          </p>

          <p class="pdf-item">
            Para que o usuário possa, de forma simples e intuitiva, gerir suas preferências quanto
            aos cookies a partir do seu navegador, poderá utilizar um dos links abaixo:
          </p>

          <ul>
            <li>
              <p class="pdf-item">Para mais informações sobre gestão de cookies no
                browser Firefox, <a
                  href="https://support.mozilla.org/pt-BR/kb/protecao-aprimorada-contra-rastreamento-firefox-desktop?redirectslug=ative-e-desative-os-cookies-que-os-sites-usam&redirectlocale=pt-BR"
                  target="_blank" rel="noreferrer">clique aqui.</a>
              </p>
            </li>
            <li>
              <p class="pdf-item">Para mais informações sobre gestão de
                cookies no browser Chrome, <a
                  href="https://support.google.com/chrome/answer/95647?hl=pt-BR" target="_blank"
                  rel="noreferrer">clique aqui.</a>
              </p>
            </li>
            <li>
              <p class="pdf-item">Para mais informações sobre gestão de cookies a
                partir do browser Internet Explorer, <a
                  href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  target="_blank" rel="noreferrer">clique aqui.</a>
              </p>
            </li>
            <li>
              <p class="pdf-item">Para mais informações sobre gestão de cookies a partir do
                Safari, <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                           target="_blank" rel="noreferrer">clique aqui.</a>
              </p>
            </li>
            <li>
              <p class="pdf-item">Para mais informações sobre gestão de cookies no browser Opera,
                <a href="https://help.opera.com/en/latest/web-preferences/"
                   target="_blank" rel="noreferrer">clique aqui.</a>
              </p>
            </li>
          </ul>

          <template v-if="is_nova_clausula">
            <h3 class="text-left">{{ nm_nova_clausula }}</h3>

            <p class="pdf-item" v-html="ds_nova_clausula">
            </p>
          </template>

          <h3>
            Onde posso obter mais informações?
          </h3>

          <p class="pdf-item">
            Se você tiver alguma dúvida sobre nosso uso de cookies ou outras tecnologias, envie um e-mail para <a :href="`mailto:${cd_email}`">{{cd_email}}</a>.
          </p>


          <template v-if="settings.settingsExtra.showPowered">
            <powered-by/>
          </template>
        </div>
      </section>
    </div>
    <v-style>
      .doc .container { background:
      {{ settings.settingsExtra.backgroundColor }} }
    </v-style>
    <v-style>
      .prose p, .prose li { color: {{ settings.settingsBody.color }}!important;
      font-size: {{ settings.settingsBody.focused_size }}!important;
      font-family: {{ settings.settingsBody.focused_font }}!important }
    </v-style>
    <v-style>
      .prose h1 { color:
      {{ settings.settingsHeading.color }}!important; } .prose h2, .prose h3,
      .prose h3 span { color: {{ settings.settingsHeading.color }}!important;
      font-size: {{ settings.settingsHeading.focused_size }}!important;
      font-family: {{ settings.settingsHeading.focused_font }}!important }
    </v-style>
    <v-style>
      .prose a { color: {{ settings.settingsLink.color }}!important; font-size:
      {{ settings.settingsLink.focused_size }}!important; font-family:
      {{ settings.settingsLink.focused_font }}!important; }
    </v-style>
  </div>
</template>
<script>
import {functions} from '@/utils/functions';

export default {
  name: 'PoliticaPrivacidade',
  created() {
  },
  methods: {},
  mixins: [functions],
  props: {
    settings: {
      type: Object,
      default() {
        return {
          settingsExtra: {},
          settingsHeading: {},
          settingsLink: {},
          settingsBody: {},
        };
      },
      required: false,
    },
    nm_fantasia: {
      type: String,
      default: '',
      required: false,
    },
    cd_site_url: {
      type: String,
      default: '',
      required: false,
    },
    cd_email: {
      type: String,
      default: '',
      required: false,
    },
    is_utilizado_cookie: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_necessarios: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_desempenho: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_rede_sociais: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_auth: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_marketing: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_nova_clausula: {
      type: Boolean,
      default: false,
      required: false,
    },
    nm_nova_clausula: {
      type: String,
      default: "",
      required: false,
    },
    ds_nova_clausula: {
      type: String,
      default: "",
      required: false,
    },
  },
  metaInfo() {
    let title;
    let favicon;

    let content;
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
      title = 'Compliance Online';
      favicon = './img/icons-co.ico/favicon-16x16.png';

      content = 'Termos de uso e privacidade para adequação na LGPD';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
      title =
        'Gerador de Política de Privacidade 🕵️‍ Gere Políticas de Privacidade GRATUITAS';
      favicon = './img/icons-easy.ico/favicon-16x16.png';

      content =
        'Gere documentos de Termos de Uso e Política de Privacidade para o seu site ou aplicativo sem custos. ';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      link: [
        {
          rel: 'icon',
          href: favicon,
          type: 'image/png',
        },
      ],
      title: title,

      meta: [
        {
          name: 'description',
          content: content,
          icon: favicon,
        },
      ],
    };
  },
};
</script>
